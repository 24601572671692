.share {
	width: 100%;
	height: 100%;
	.video {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		#sharingVideo {
			position: absolute;
			width: 100%;
			height: 100%;
			visibility: hidden;
		}
		#canvasContainer {
			visibility: hidden;
		}
		.canvasImage {
			max-width: 100%;
			max-height: 100%;
			position: absolute;
		}
	}
}
