.ChatContainer {
	width: 200px;
	height: 100%;
	position: fixed;
	background: white;
	top: 0;
	right: 0;
	.messages {
		height: calc(100% - 84px);
	}
}
