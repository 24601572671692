// .container {
// 	display: flex;
// 	flex-wrap: wrap;
// 	position: fixed;
// 	top: 0;
// 	left: 0;
// }

.flex {
	display: flex; /* displays flex-items (children) inline */
	flex-wrap: wrap; /* enables them to wrap (default: nowrap) */
	/* 16:9 ratio */
	// width: 160px;
	// height: 90px;
	// margin: 5px;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

.flex > div {
	flex-grow: 1; /* enabled (default: 0); can grow/expand beyond 50% of the parent's width */
	flex-basis: 50%; /* initial width set to 50% because none of the items will be less than that, no matter how many of them */
	border: 1px solid; /* just to see the result better */
	box-sizing: border-box; /* recommended because of the border; otherwise you'd need to use the CSS calc() function: "flex-basis: calc(50% - 2px);" -2px because of the left and right border, which is 1px each; same applies for margins, if you're going to use them, then you also need to use the calc(), e.g.: calc(x% - twice the defined margin) */
	background: #eff0f1;
}
