.cameraContainer {
	display: flex;
	flex-wrap: wrap;
	position: fixed;
	top: 0;
	right: 0;
	width: calc(100% - 73px);
	height: 100%;
	& > div {
		flex-grow: 2; /* enabled (default: 0); can grow/expand beyond 50% of the parent's width */
		flex-basis: 33%; /* initial width set to 50% because none of the items will be less than that, no matter how many of them */
		border: 1px solid; /* just to see the result better */
		box-sizing: border-box; /* recommended because of the border; otherwise you'd need to use the CSS calc() function: "flex-basis: calc(50% - 2px);" -2px because of the left and right border, which is 1px each; same applies for margins, if you're going to use them, then you also need to use the calc(), e.g.: calc(x% - twice the defined margin) */
		background: #eff0f1;
		position: relative;
	}
	&.single {
		& > div {
			flex-basis: 100%;
			&.camera {
				video {
					max-height: 100%;
				}
			}
		}
	}
	&.middle {
		& > div {
			flex-basis: 50%;
		}
	}
	&.third {
		& > div {
			flex-basis: 33%;
		}
	}
	&.shareActive {
		width: calc(40% - 73px);
		right: 0%;
		& > div {
			&.camera {
				video {
					max-width: 100%;
				}
			}
		}
	}
}
.shareContainer {
	display: flex;
	flex-wrap: wrap;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: calc(60%);
	margin-left: 73px;
	video {
		max-width: 100%;
	}
	&.shareInactive {
		display: none;
	}
}

.buttons {
	z-index: 1;
	position: absolute;
}
.floating-text {
	position: absolute;
	z-index: 10000;
	color: green;
}
.fab-button {
	position: fixed !important;
	right: 10px;
	top: 1px;
	z-index: 2;
}
