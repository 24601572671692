#UserInfoContainer {
	background: rgba(0, 0, 0, 0.3);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	form {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 200px;
		input {
			background: white;
		}
	}
}
